import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { colors } from '../../const/Styles';
import { MovieDisplay } from '../../../utils/Video';
import { RegisteredVideoMap } from '../../../store/RecordingStore';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../const/Routes';

const Container = styled.span`
    position: relative;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    padding: 10px;
`;

const StyledVideo = styled.video`
    height: 100%;
    width: 100%;
`;

const Overlay = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 3;
    color: ${colors.white};
    font-size: 128px;
    font-weight: bold;
    opacity: 0.8;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

const ImageWrapper = styled.div`
    text-align: center;
`;

const Img = styled.img`
    height: 100%;
    width: 75%;
    object-fit: cover;
`;

type Props = {
    customerId: number,
    registeredVideo?: RegisteredVideoMap
    movieDisplay?: MovieDisplay
}

const CustomerBestSwingMovieThumbnail: React.FC<Props> = ({
    customerId,
    registeredVideo,
    movieDisplay,
}) => {
    const thumbnailUrl = useMemo(() => {
        movieDisplay?.fetchSrc();
        return movieDisplay?.thumbnail_signed_url;
    }, [movieDisplay]);

    const history = useHistory();

    const handleClickOverlay = useCallback(() => {
        if (registeredVideo?.video) {
            console.log(registeredVideo.video);
            history.push(Routes.LESSON_PLAY_SWING, {
                customerId: `${customerId}`,
                video: registeredVideo.video,
            });
            return;
        }

        if (movieDisplay) {
            history.push(Routes.LESSON_PAST_SWING, {
                movie: movieDisplay,
                movieIndex: 1,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerId, registeredVideo, movieDisplay])

    if (!registeredVideo?.video && !movieDisplay) {
        return null;
    }

    return (
        <Container>
            {registeredVideo?.video && (
                <StyledVideo
                    src={URL.createObjectURL(registeredVideo.video)}
                    controls={false}
                    autoPlay={false}
                />
            )}
            {thumbnailUrl && !registeredVideo && <ImageWrapper><Img src={thumbnailUrl} /></ImageWrapper>}
            <Overlay onClick={handleClickOverlay}>{registeredVideo?.number || ''}</Overlay>
        </Container>
    )
};

export default CustomerBestSwingMovieThumbnail;
